/* @flow */
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ADD_TO_PLAN } from '../actions';
import { propsToReducerString } from '../utils';
import type { Action } from '../types';
import type { OwnProps, AddToPlanFromRegistrationCTA, Props, DispatchProps } from './types';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    dispatchReduxAddToPlan: (props: AddToPlanFromRegistrationCTA) => {
        return dispatch({ type: ADD_TO_PLAN, eventId: props.eventId, key: propsToReducerString(props) });
    },
});

export default connect<Props, OwnProps, _, DispatchProps, _, _>(null, mapDispatchToProps);
