/* @flow */
import React, { useState } from 'react';
import { Text, Button, paletteHelper, spacingHelper, Card } from '@rea-group/construct-kit-core';
import { type User } from '@realestate.com.au/user';
import styled from 'styled-components';
import { RegistrationModal, RegistrationModalType } from '@realestate.com.au/planner-event-service-ui';
import MyREASignInForm from '../../../myrea-sign-in';
import type { Address } from '../../../../models/lexa';
import type { AdaptedGeneralFeatures, AdaptedPropertySize } from './adaptData';
import type { AddToPlanFromRegistrationCTA, EventItem, EventTrackers } from './types';
import type { Action } from '../types';

const StyledCard = styled(Card)`
    background-color: ${paletteHelper('backgroundSecondaryBase')};
    margin-top: ${spacingHelper('large')};
    padding: ${spacingHelper('large')};
    box-shadow: none;
`;

const StyledText = styled(Text)`
    padding: ${spacingHelper('extraSmall 0 large 0')};
`;

export type ViewProps = {
    getUser: () => User,
    address: Address,
    generalFeatures: ?AdaptedGeneralFeatures,
    propertySize: ?AdaptedPropertySize,
    events: Array<EventItem>,
    templatedUrl: string,
    dispatchReduxAddToPlan: (props: AddToPlanFromRegistrationCTA) => Action,
    eventTrackers: EventTrackers,
};

export const View = ({
    getUser,
    address,
    events,
    templatedUrl,
    dispatchReduxAddToPlan,
    eventTrackers,
    generalFeatures,
    propertySize,
}: ViewProps) => {
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const { startTime, endTime } = events.length === 1 ? events[0].input : {};

    const openRegistrationModal = () => {
        eventTrackers.inspectionRegistrationFormOpen(startTime, endTime)();
        setShowRegistrationModal(true);
    };
    const handleClick = () => {
        if (getUser().isSignedIn) {
            openRegistrationModal();
        } else {
            setShowSignIn(true);
        }
    };

    return (
        <StyledCard>
            <Text variant="title03">{`Let the agent know you’re coming`}</Text>
            <StyledText>Definitely inspecting? Give the agent a heads up and check in faster on the day.</StyledText>
            <Button onClick={handleClick}>Register for inspection</Button>
            <RegistrationModal
                events={events}
                address={address}
                modalMountID="#argonaut-wrapper"
                templatedUrl={templatedUrl}
                eventTrackers={eventTrackers}
                onRegistrationSuccess={(props) => {
                    eventTrackers.inspectionRegistrationFormSubmitted(props.startTime, props.endTime)();
                    eventTrackers.addToPlan(props.startTime, props.endTime);
                    dispatchReduxAddToPlan(props);
                }}
                isOpen={showRegistrationModal}
                onClose={() => setShowRegistrationModal(false)}
                generalFeatures={generalFeatures}
                propertySize={propertySize}
                modalType={RegistrationModalType.WithConfirmation}
            />
            {showSignIn && (
                <MyREASignInForm
                    showPrompt={false}
                    onClose={() => setShowSignIn(false)}
                    onSuccess={openRegistrationModal}
                    intent="register_for_inspection"
                />
            )}
        </StyledCard>
    );
};
