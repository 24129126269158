/* @flow */
import React from 'react';
import { adaptData } from './adaptData';
import { View } from './View';
import connect from './connected';
import type { Props } from './types';

export const RegistrationCTACard = ({
    getUser,
    inspections,
    templatedUrl,
    dispatchReduxAddToPlan,
    channel,
    eventTracker,
    generalFeatures,
    propertySizes,
}: Props) => {
    const adaptedData = adaptData(inspections, channel, eventTracker, generalFeatures, propertySizes);

    return (
        <View
            {...adaptedData}
            getUser={getUser}
            templatedUrl={templatedUrl}
            dispatchReduxAddToPlan={dispatchReduxAddToPlan}
        />
    );
};

export default connect(RegistrationCTACard);
