/* @flow */
import React, { useContext } from 'react';
import IreWidget from '@realestate.com.au/inspect-realestate';
import createIreComponent from '../create-ire-component';
import EventsList from '../events-list';
import adaptOfiData from './adaptOfiData';
import adaptIreData from './adaptIreData';
import ChannelContext from '../../../../util/ChannelContext';
import ErrorBoundaryNewRelicReporter from '../../../error-boundary-new-relic-reporter';
import { createBrowserUser, type User } from '@realestate.com.au/user';
import type { DataGettersType } from '../events-list/types';
import { ContactAgentLink } from '../events-list/ContactAgentLink';

type Props = {|
    contactAgentEl: () => ?Element,
    data: DataGettersType,
    id?: string,
    getUser?: () => User,
    shouldHideTitle?: boolean,
|};

const NoInspectionsContent = ({ viewEmailAgentTracker, contactAgentEl }) => {
    return (
        <p>
            There are no upcoming inspections for this property. &nbsp;
            <ContactAgentLink contactAgentEl={contactAgentEl} onClick={viewEmailAgentTracker} />
            &nbsp;to see this place.
        </p>
    );
};

export const createOpenForInspectionView =
    (constructIre: Function, cleanupIre: Function) =>
    ({ data, contactAgentEl, getUser = createBrowserUser, shouldHideTitle = false }: Props) => {
        const channel = useContext(ChannelContext);
        const adaptedIreData = adaptIreData(channel)(data);
        const ireCreator = createIreComponent(constructIre, adaptedIreData);
        const hasIreIntegration = adaptedIreData?.ireData.reaProperty.hasIreIntegration ?? false;
        const showIreRegisterButton =
            hasIreIntegration && (adaptedIreData?.ireData.reaProperty.ireInspectionTimesCount ?? 0) > 0;
        const {
            title,
            inspectionDescription,
            listingId,
            generalFeatures,
            propertySizes,
            templatedUrl,
            registrationsEnabled,
            openTimes,
        } = adaptOfiData(channel, shouldHideTitle)(data);
        const viewEmailAgentTracker = () => data.getEventTracker().viewEmailAgentForm('contact form');

        return (
            <ErrorBoundaryNewRelicReporter component="OpenForInspection">
                <EventsList
                    eventTracker={data.getEventTracker()}
                    title={title}
                    inspectionDescription={inspectionDescription}
                    templatedUrl={templatedUrl}
                    inspections={openTimes}
                    initIre={ireCreator}
                    cleanupIre={cleanupIre}
                    hasIreIntegration={hasIreIntegration}
                    contactAgentEl={contactAgentEl}
                    viewEmailAgentTracker={viewEmailAgentTracker}
                    listingId={listingId}
                    generalFeatures={generalFeatures}
                    propertySizes={propertySizes}
                    getUser={getUser}
                    channel={channel}
                    showIreRegisterButton={showIreRegisterButton}
                    registrationsEnabled={registrationsEnabled}
                    noInspectionsContent={
                        <NoInspectionsContent
                            viewEmailAgentTracker={viewEmailAgentTracker}
                            contactAgentEl={contactAgentEl}
                        />
                    }
                />
            </ErrorBoundaryNewRelicReporter>
        );
    };

export default createOpenForInspectionView(IreWidget.createIreReplatform, IreWidget.cleanupIreReplatform);
