import React from 'react';
import { Stack, Link } from '@rea-group/construct-kit-core';
import { ViewProps } from './types';
import { Header } from './Header';
import Content from './content';

const View = ({ header, content, contactAgentEl }: ViewProps) => {
    return (
        <Stack inset="medium 0" gap="medium">
            <Header {...header} />
            <Content {...content} contactAgentEl={contactAgentEl} />
            <Link variant="button-naked" fullWidth={true}>
                See my inspection plan
            </Link>
        </Stack>
    );
};

export default View;
