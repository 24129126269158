/* @flow */
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ADD_TO_PLAN } from '../actions';
import { propsToReducerString } from '../utils';
import type { Action } from '../types';
import type { OwnProps, DispatchProps, ConnectedProps } from './types';

const mapDispatchToProps = (dispatch: Dispatch<Action>, props: OwnProps) => ({
    dispatchReduxAddToPlan: (eventId: string) => {
        return dispatch({ type: ADD_TO_PLAN, eventId, key: propsToReducerString(props) });
    },
});

const mapStateToProps = ({ recentPlannerChanges }, props) => {
    const key = propsToReducerString(props);
    const eventId =
        recentPlannerChanges && recentPlannerChanges.hasOwnProperty(key) ? recentPlannerChanges[key] : props.eventId;
    return {
        ...props,
        eventId,
    };
};

export default connect<ConnectedProps, OwnProps, Object, DispatchProps, _, _>(mapStateToProps, mapDispatchToProps);
