/* @flow */

import type { PersonalisedAuction, PersonalisedInspection, PlannerEventType } from '../../../models/lexa';
import { EventType as CalendarEventType } from '@realestate.com.au/planner-add-to-calendar';

export const calendarEventTypeMap: { [key: PlannerEventType]: CalendarEventType } = {
    AUCTION: 'Auction',
    INSPECTION: 'Inspection',
    DISPLAY_SUITE: 'Display_Suite',
};

export const adaptEventData = (event: PersonalisedAuction | PersonalisedInspection) => {
    return event.plannerEventType === 'AUCTION'
        ? {
              display: ((event: any): PersonalisedAuction).dateTime.display,
              startTime: ((event: any): PersonalisedAuction).dateTime.value,
              endTime: undefined,
          }
        : {
              display: ((event: any): PersonalisedInspection).display,
              startTime: ((event: any): PersonalisedInspection).startTime,
              endTime: ((event: any): PersonalisedInspection).endTime,
          };
};

export type PropsToReducerStringParam = {
    listingId: string,
    eventType: PlannerEventType,
    startTime: string,
    endTime?: string,
};

export const propsToReducerString = (props: PropsToReducerStringParam) => {
    const { eventType, listingId, startTime, endTime } = props;
    const elements = [eventType, listingId, startTime, endTime].filter((el) => !!el);
    return elements.join('_');
};
