/* @flow */

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';
import type { EventsListProps, OFIViewProps } from './types';
import CalendarEvent from '../calendar-event/View';
import ScrollToElement from '../../ScrollToElement';
import { useCheckInspection } from '../../contact-agent/contact-agent-form/ConsumerRequestsProvider';
import './styles.scss';
import {
    Button,
    spacingHelper,
    paletteHelper,
    Link,
    Stack,
    Text,
    ArrowDownMd,
    ArrowUpMd,
} from '@rea-group/construct-kit-core';
import SectionHeading from '../../section-heading';
import styled from 'styled-components';
import { type User } from '@realestate.com.au/user';
import type { Channel } from '../../../../models/Channel';
import RegistrationCTACard from '../registration-cta-card';

type EnquiryFormButtonProps = {
    contactAgentEl: () => ?Element,
    viewEmailAgentTracker: () => void,
};
type ShowMoreButtonProps = {
    collapsed: boolean,
    inspections: OFIViewProps,
    onClick: () => void,
};

type InspectionDescriptionProps = {
    textContent: ?string,
};

type InspectionEventListProps = {
    inspections: OFIViewProps,
    isCollapsed: boolean,
    listingId: string,
    templatedUrl: string,
    getUser: () => User,
    channel: Channel,
    showIreRegisterButton: boolean,
    registrationsEnabled: boolean,
};

type InspectionsSectionProps = {
    templatedUrl: string,
    inspections: OFIViewProps,
    hasIreIntegration: boolean,
    listingId: string,
    shouldShowExpandButton: boolean,
    collapsed: boolean,
    getUser: () => User,
    channel: Channel,
    showIreRegisterButton: boolean,
    registrationsEnabled: boolean,
    noInspectionsContent: Node,
};

const PlannedInspectionsButton = styled(Link).attrs({ variant: 'button-outline' })`
    margin-bottom: ${spacingHelper('medium')};
    margin-top: ${spacingHelper('large')};
`;

const CalendarEventWrapper = styled.div`
    padding: ${spacingHelper('medium 0')};
    border-bottom: 1px solid ${paletteHelper('borderSecondary')};

    &:first-child {
        padding-top: 0;
    }
`;

const EnquiryButtonWrapper = styled.div`
    margin: ${spacingHelper('medium 0')};
`;

const StyledIrePlaceholder = styled.div`
    display: ${(props: { $isHidden: boolean }) => (props.$isHidden ? 'none' : 'block')};
`;

const InspectionText = styled(Text)`
    margin: ${spacingHelper('medium 0')};
`;

const InspectionsContainerWrapper = styled(Stack)`
    border: 1px solid ${paletteHelper('borderSecondary')};
    border-radius: ${spacingHelper('small')};
`;

const EnquiryFormButton = ({ contactAgentEl, viewEmailAgentTracker }: EnquiryFormButtonProps) => {
    const checkInspection = useCheckInspection();
    const afterScroll = () => {
        viewEmailAgentTracker();
        checkInspection();
    };
    return (
        <EnquiryButtonWrapper className="events-list__enquiry-form">
            <span>None of these times suit? </span>
            <ScrollToElement elementRef={contactAgentEl} afterScroll={afterScroll}>
                {(scrollOnClick) => (
                    <Button variant="link-inline" onClick={scrollOnClick}>
                        Contact the agent
                    </Button>
                )}
            </ScrollToElement>
        </EnquiryButtonWrapper>
    );
};

const ShowMoreButton = ({ collapsed, inspections, onClick }: ShowMoreButtonProps) => {
    return collapsed ? (
        <Button
            variant="link-primary"
            className="events-list__show-more"
            onClick={onClick}
            icon={<ArrowDownMd />}
            iconPlacement="right"
        >
            Show more times ({inspections.length - 4})
        </Button>
    ) : (
        <Button
            variant="link-primary"
            className="events-list__show-less"
            onClick={onClick}
            icon={<ArrowUpMd />}
            iconPlacement="right"
        >
            Show fewer times
        </Button>
    );
};

const InspectionDescription = ({ textContent }: InspectionDescriptionProps) =>
    !!textContent && <InspectionText className="events-list__notes-text">{textContent}</InspectionText>;

const InspectionEventList = ({
    inspections,
    isCollapsed,
    listingId,
    templatedUrl,
    getUser,
    channel,
    showIreRegisterButton,
    registrationsEnabled,
}: InspectionEventListProps) => {
    const inspectionsNumber = inspections?.length ?? 0;
    return inspections.slice(0, isCollapsed ? 4 : inspectionsNumber).map((inspection, index) => (
        <CalendarEventWrapper key={index}>
            <CalendarEvent
                {...inspection}
                listingId={listingId}
                templatedUrl={templatedUrl}
                getUser={getUser}
                channel={channel}
                showIreRegisterButton={showIreRegisterButton}
                shouldShowRegistrationButton={registrationsEnabled}
            />
        </CalendarEventWrapper>
    ));
};

const InspectionsSection = ({
    inspections,
    hasIreIntegration,
    listingId,
    shouldShowExpandButton,
    showIreRegisterButton,
    collapsed,
    templatedUrl,
    getUser,
    channel,
    registrationsEnabled,
    noInspectionsContent,
}: InspectionsSectionProps) => {
    if (inspections.length === 0 && !hasIreIntegration) {
        return <div className="no-inspections">{noInspectionsContent}</div>;
    } else {
        return (
            <InspectionEventList
                inspections={inspections}
                isCollapsed={shouldShowExpandButton && collapsed}
                listingId={listingId}
                templatedUrl={templatedUrl}
                getUser={getUser}
                channel={channel}
                showIreRegisterButton={showIreRegisterButton}
                registrationsEnabled={registrationsEnabled}
            />
        );
    }
};

const EventsList = ({
    eventTracker,
    templatedUrl,
    inspections,
    initIre,
    cleanupIre,
    hasIreIntegration,
    contactAgentEl,
    viewEmailAgentTracker,
    listingId,
    getUser,
    generalFeatures,
    propertySizes,
    channel,
    title,
    inspectionDescription,
    showIreRegisterButton,
    registrationsEnabled,
    noInspectionsContent,
}: EventsListProps) => {
    const [collapsed, setCollapsed] = useState(true);
    const irePlaceholder = useRef();

    const shouldShowExpandButton = inspections.length > 4;
    const shouldShowEnquiryFormButton =
        !hasIreIntegration &&
        ((inspections.length > 0 && inspections.length <= 4) || (inspections.length > 4 && !collapsed));
    const shouldShowInspectionDescription = inspections.length > 0 && !!inspectionDescription;
    const plannerPath = '/planner';
    const trackingTag = `sourcePage=rea:${channel}:property-details&sourceElement=see_planned_inspections_pdp`;
    const plannerUrl = `${plannerPath}?${trackingTag}`;
    const shouldShowRegistrationRevealed = inspections.length > 0 && registrationsEnabled;

    const getStackInset = () => {
        if (inspections.length > 0) {
            return '1rem 1rem 0 1rem';
        } else {
            if (hasIreIntegration) {
                return '0 1rem';
            } else {
                return '1rem';
            }
        }
    };

    const handleClick = () => setCollapsed(!collapsed);

    useEffect(() => {
        if (hasIreIntegration) {
            initIre && initIre(irePlaceholder.current, getUser?.()?.isSignedIn ?? false);
        }
        return () => {
            cleanupIre && cleanupIre();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="events-list">
            {title && <SectionHeading className="events-list__heading">{title}</SectionHeading>}
            <InspectionsContainerWrapper gap="0" inset={getStackInset()}>
                <div className="inspectionsContainer">
                    <InspectionsSection
                        templatedUrl={templatedUrl}
                        inspections={inspections}
                        hasIreIntegration={hasIreIntegration}
                        contactAgentEl={contactAgentEl}
                        viewEmailAgentTracker={viewEmailAgentTracker}
                        listingId={listingId}
                        shouldShowExpandButton={shouldShowExpandButton}
                        collapsed={collapsed}
                        getUser={getUser}
                        channel={channel}
                        showIreRegisterButton={showIreRegisterButton}
                        registrationsEnabled={registrationsEnabled}
                        noInspectionsContent={noInspectionsContent}
                    />
                    <StyledIrePlaceholder
                        className="ireplaceholder"
                        ref={irePlaceholder}
                        $isHidden={shouldShowExpandButton ? collapsed : false}
                    />
                </div>
                {shouldShowEnquiryFormButton && (
                    <EnquiryFormButton contactAgentEl={contactAgentEl} viewEmailAgentTracker={viewEmailAgentTracker} />
                )}
                {shouldShowExpandButton && (
                    <ShowMoreButton collapsed={collapsed} inspections={inspections} onClick={handleClick} />
                )}
                {shouldShowInspectionDescription && (
                    <InspectionDescription className="events-list__notes" textContent={inspectionDescription} />
                )}
            </InspectionsContainerWrapper>
            {shouldShowRegistrationRevealed && (
                <RegistrationCTACard
                    getUser={getUser}
                    inspections={inspections}
                    generalFeatures={generalFeatures}
                    propertySizes={propertySizes}
                    templatedUrl={templatedUrl}
                    channel={channel}
                    eventTracker={eventTracker}
                />
            )}
            <PlannedInspectionsButton href={plannerUrl}>See my inspection plan</PlannedInspectionsButton>
        </div>
    );
};

export default EventsList;
