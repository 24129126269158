import { GetComponentGetters, ProductComponent, ReplaceSchemaDataWithEventTracker } from '../../../../types';
import {
    EventsActionFragment,
    ResiInspectionsInfoContentFragment,
    ResiInspectionsListContentFragment,
} from '../../../../types/generated/product-components';

export type Props = {
    data: GetComponentGetters;
    contactAgentEl: () => Element | null;
};

export type InspectionsComponent = ProductComponent<'InspectionsComponent'>;

export type ResiInspectionsInfoContentProps = ResiInspectionsInfoContentFragment & {
    requestInspectionAction: ReplaceSchemaDataWithEventTracker<
        EventsActionFragment,
        { interactionEventSchemaData: string }
    >;
};

export type ResiInspectionsListContentProps = ResiInspectionsListContentFragment & {
    requestInspectionAction: ReplaceSchemaDataWithEventTracker<
        EventsActionFragment,
        { interactionEventSchemaData: string }
    >;
};

export type AdaptedProps = {
    header: InspectionsComponent['header'];
    content: ResiInspectionsInfoContentProps | ResiInspectionsListContentProps;
};

export type ViewProps = AdaptedProps & { contactAgentEl: () => Element | null };

export type HeaderProps = InspectionsComponent['header'];

export const isResiInspectionsInfoContentProps = (
    props: ResiInspectionsInfoContentProps | ResiInspectionsListContentProps
): props is ResiInspectionsInfoContentProps => props.__typename === 'ResiInspectionsInfoContent';

export const isResiInspectionsListContentProps = (
    props: ResiInspectionsInfoContentProps | ResiInspectionsListContentProps
): props is ResiInspectionsListContentProps => props.__typename === 'ResiInspectionsListContent';
