/* @flow */

import React from 'react';
import { addEventToPlanner, type EventInput } from '@realestate.com.au/planner-event-service';
import BrowserOnly from '../../../browser-only';
import { Button, EventAddLg } from '@rea-group/construct-kit-core';
import type { ConnectedProps } from './types';
import appLogger from '../../../../util/logging/appLogger';

const AddToCalendarPackage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "@realestate.com.au/planner-add-to-calendar" */ '@realestate.com.au/planner-add-to-calendar'
        )
);

export const View = (props: ConnectedProps) => {
    const {
        startTime,
        endTime,
        eventTrackers,
        address,
        listingId,
        templatedUrl,
        eventType,
        addToCalendarEvent,
        dispatchReduxAddToPlan,
        eventId,
        getUser,
        trackAddToPlan,
        shouldShowRegistrationButton,
    } = props;

    const inspectionsSectionTrackers = eventTrackers('inspections_section');
    const calendarButtonEventTriggers = {
        onSaveToCalendar: inspectionsSectionTrackers.trackSaveToCalendar,
        onSaveToCalendarSuccess: async () => {
            if (getUser && getUser().isSignedIn && !eventId) {
                try {
                    const input: EventInput = {
                        eventType,
                        listingId,
                        startTime,
                        endTime,
                        source: 'REA',
                    };
                    const { eventId: newEventId } = await addEventToPlanner(input);
                    dispatchReduxAddToPlan(newEventId);
                    trackAddToPlan(shouldShowRegistrationButton ? 'inspection_registration' : undefined)('add');
                } catch (error) {
                    appLogger.error('Failed to add inspection to planner')(error);
                }
            }
            inspectionsSectionTrackers.trackSaveToCalendarSuccess();
        },
    };

    return (
        <BrowserOnly
            name="AddToCalendar"
            fallback={
                <Button
                    icon={<EventAddLg />}
                    compact={true}
                    variant="naked"
                    sizeVariant="small"
                    label="Add to calendar"
                    hideLabel={false}
                />
            }
        >
            <AddToCalendarPackage
                event={addToCalendarEvent}
                eventTriggers={calendarButtonEventTriggers}
                address={address}
                modalMountID="#argonaut-wrapper"
                templatedUrl={templatedUrl}
                responsiveAddToCalendarButton={false}
                icon={<EventAddLg />}
                compact={true}
                variant="naked"
                sizeVariant="small"
                label="Add to calendar"
                hideLabel={true}
            />
        </BrowserOnly>
    );
};
