/* @flow */
import { useEffect, useContext } from 'react';
import { scrollTo } from '../../util/DOMUtils';
import StickyBrandingContext from './StickyBrandingContext';

const useScrollToElement = (when: () => boolean, elementRef: () => ?Element) => {
    const { stickyBrandingOffset } = useContext(StickyBrandingContext);
    useEffect(() => {
        const scrollToElement = elementRef();

        if (when() && scrollToElement) {
            setTimeout(() => {
                scrollTo(scrollToElement, stickyBrandingOffset);
            }, 500);
        }
    }, [elementRef, stickyBrandingOffset, when]);
};

export default useScrollToElement;
