/* @flow */
import type { IreCreator, AdaptedIREListingData } from './types';
import appLogger from '../../../../util/logging/appLogger';

export const IRE_OPEN_MODAL_EVENT = 'ire_open_modal';

export default function (constructIre: Function, data: ?AdaptedIREListingData): IreCreator {
    return (containerRef, isSignedIn) => {
        if (!data) return;

        data.widgetsData.inspectionsContainer = containerRef;
        data.isSignedIn = isSignedIn;

        const errorLogger = (message: string, error: Error) => {
            appLogger.error(message)(error);
        };

        constructIre(data, IRE_OPEN_MODAL_EVENT, errorLogger);
    };
}
