/* @flow */
import { type Channel as ChannelType } from '../../../../models/Channel';
import { compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type { SaveToCalendarProps } from '../auction/adaptLexaData';
import type { OpenTimesProps } from '../calendar-event/types';
import PersonalisedInspections from './PersonalisedInspections.graphql';
import type { DataGettersType } from '../events-list/types';

const constructOFI = ({
    inspections,
    address,
    listingId,
    eventTrackers,
    addToPlan,
    channel,
    registrationEventTrackers,
}): Array<SaveToCalendarProps> =>
    inspections.map((inspection) => {
        const trackingData = {
            startTime: inspection.startTime,
            endTime: inspection.endTime,
            status: channel === 'sold' ? 'sold' : 'active',
            channel: channel,
            inPageSource: 'add_to_plan_success_modal_register_cta',
        };
        return {
            address,
            event: inspection,
            eventTrackers: eventTrackers,
            listingId,
            trackAddToPlan: addToPlan({ ...trackingData, itemType: 'inspection' }),
            registrationEventTrackers: {
                inspectionRegistrationFormOpen: registrationEventTrackers.inspectionRegistrationFormOpen(trackingData),
                inspectionRegistrationFormSubmitted:
                    registrationEventTrackers.inspectionRegistrationFormSubmitted(trackingData),
            },
        };
    });

const adaptLexaData = compose(getFragmentData(PersonalisedInspections), (data: DataGettersType) => ({
    listing: data.getListing(),
    inspections: data.getInspections(),
}));

export default (channel: ChannelType, shouldHideTitle: boolean) =>
    (data: DataGettersType): OpenTimesProps => {
        const { inspections, listing } = adaptLexaData(data);
        const { address, id: listingId, generalFeatures, propertySizes } = listing;
        const eventTracker = data.getEventTracker();

        const eventTrackers = (inPageSource?: string) => {
            const trackSaveToCalendar = eventTracker.saveToCalendar('OFI', inPageSource);
            const trackSaveToCalendarSuccess = eventTracker.saveToCalendarSuccess('OFI', inPageSource);
            return {
                trackSaveToCalendar,
                trackSaveToCalendarSuccess,
            };
        };

        return {
            title: shouldHideTitle ? null : 'Inspections',
            listingId,
            generalFeatures,
            propertySizes,
            templatedUrl: listing.media.mainImage.templatedUrl,
            registrationsEnabled: listing.inspectionTimes.registrationsEnabled,
            inspectionDescription: null,
            openTimes: constructOFI({
                inspections,
                listingId,
                address,
                eventTrackers,
                addToPlan: eventTracker.addToPlan,
                channel: channel,
                registrationEventTrackers: {
                    inspectionRegistrationFormOpen: eventTracker.inspectionRegistrationFormOpen,
                    inspectionRegistrationFormSubmitted: eventTracker.inspectionRegistrationFormSubmitted,
                },
            }),
        };
    };
