/* @flow */

import { type Channel as ChannelType } from '../../../../models/Channel';
import type { BuyResidentialListing, RentResidentialListing } from '../../../../models/lexa';
import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type { AdaptedIREListingData, IREListingData } from '../create-ire-component/types';
import InspectRealestateBuy from './InspectRealestateBuy.graphql';
import InspectRealestateRent from './InspectRealestateRent.graphql';
import type { DataGettersType } from '../events-list/types';
import type { InspectionData, InspectionDate } from '../../../../models/lexa';

type DataType = BuyResidentialListing | RentResidentialListing;

/*
// AvailableTime example
{
    "day":"Fri 22 Mar 2024",
    "timeZone":"UTC+11:00",
    "times":[
       {
          "startTime":"1:00 PM",
          "endTime":"1:15 PM",
          "timeType":2
       }
    ]
 }
*/
type AvailableTime = {
    day: string,
    timeZone: string,
    times: Array<{
        startTime: string,
        endTime: string,
    }>,
};
type IreInspectionTimesResponse = {
    inspStatus?: number,
    ireEnquiryID?: number,
    irePropertyID?: number,
    availableTimes: Array<AvailableTime>,
};

// startTime format is "2024-03-22T13:00:00+11:00"
const getTimezone = (startTime: string) => {
    return `UTC+${startTime.split('+')[1]}`;
};

// time format is "1:00 pm–1:15 pm"
const getStartTime = (time: string) => {
    return time.split('–')[0].toUpperCase();
};

// time format is "1:00 pm–1:15 pm"
const getEndTime = (time: string) => {
    return time.split('–')[1].toUpperCase();
};

const getShortDateWithYear = (date: InspectionDate): string => {
    return `${date.day.display.shortDate} ${date.times[0].startTime.substring(0, 4)}`;
};

const transformToAvailableTimes = (inspectionDates: Array<InspectionDate>): Array<AvailableTime> =>
    inspectionDates.map((date) => ({
        day: getShortDateWithYear(date),
        timeZone: getTimezone(date.times[0].startTime),
        times: date.times.map((time) => ({
            startTime: getStartTime(time.display.time),
            endTime: getEndTime(time.display.time),
        })),
    }));

const filterIREInspectionTimes = (inspectionTimes: Array<InspectionDate>): Array<InspectionDate> =>
    inspectionTimes.map((inspectionDate) => ({
        day: inspectionDate.day,
        times: inspectionDate.times.filter((time) => time.__typename === 'IREInspectionTime'),
    }));

const filterDateWithNoInspectionTimes = (inspectionTimes: Array<InspectionDate>): Array<InspectionDate> =>
    inspectionTimes.filter((inspectionDate) => inspectionDate.times.length > 0);

export const transformToIreInspectionTimes = (inspectionTimes: InspectionData): IreInspectionTimesResponse => {
    return {
        inspStatus: inspectionTimes.ireIntegration?.book.inspStatus,
        ireEnquiryID: inspectionTimes.ireIntegration?.book.ireEnquiryID,
        irePropertyID: inspectionTimes.ireIntegration?.book.irePropertyID,
        availableTimes: compose(
            transformToAvailableTimes,
            filterDateWithNoInspectionTimes,
            filterIREInspectionTimes
        )(inspectionTimes.groupedByDay),
    };
};

const dataAdapter =
    (channel: ChannelType) =>
    (residentialListing: DataType): ?IREListingData => {
        const { listingCompany, id, address, price, inspections, media, inspectionTimes } = residentialListing;
        if (!listingCompany) return null;

        return {
            widgetsData: {
                agentLogo: {
                    agentLogoColor: listingCompany.branding.primaryColour,
                    agentLogoUrl:
                        listingCompany.media.logo && listingCompany.media.logo.templatedUrl.replace('{size}', '340x64'),
                },
                channel,
            },
            ireData: {
                reaAgentID: listingCompany.id,
                reaProperty: {
                    reaPropertyID: id,
                    address: {
                        street: address.display.shortAddress,
                        suburb: address.suburb,
                        state: address.state,
                        postCode: address.postcode,
                    },
                    displayPrice: price.display,
                    propType: channel === 'rent' ? 0 : 1,
                    openTimes: inspections,
                    hasIreIntegration: !!inspectionTimes.ireIntegration,
                    ireInspectionTimesCount: inspectionTimes.ireInspectionTimesCount,
                    ireInspectionTimes: transformToIreInspectionTimes(inspectionTimes),
                    mainImageTemplate: media.mainImage.templatedUrl.replace('{size}', '{width}x{height}-fit'),
                },
            },
        };
    };

const getAdaptedListingData = (channel: ChannelType) =>
    compose(
        dataAdapter(channel),
        getFragmentData(channel === 'buy' ? InspectRealestateBuy : InspectRealestateRent),
        (data: DataGettersType) => data.getListing()
    );

const getEventTrackerData = (data, ireInspectionTimesCount, channel) => ({
    eventTrackers: {
        trackFormOpen: () => data.getEventTracker().ireFormOpen('ire inspection', ireInspectionTimesCount),
        trackFormSubmit: (trackingData) => {
            data.getEventTracker().ireFormSubmit(trackingData);
        },
        trackAddToPlan: (startTime, endTime) => {
            data.getEventTracker().addToPlan({
                startTime,
                endTime,
                status: channel === 'sold' ? 'sold' : 'active',
                channel,
                itemType: 'inspection',
            })()('add');
        },
    },
});

export default (channel: ChannelType) =>
    (data: DataGettersType): ?AdaptedIREListingData => {
        const listingData = getAdaptedListingData(channel)(data);
        const ireInspectionTimesCount = listingData ? listingData.ireData.reaProperty.ireInspectionTimesCount : 0;
        return listingData
            ? {
                  ...listingData,
                  ...getEventTrackerData(data, ireInspectionTimesCount, channel),
              }
            : null;
    };
