import React from 'react';
import { InfoContent } from './InfoContent';
import { ListContent } from './ListContent';
import { isResiInspectionsInfoContentProps, isResiInspectionsListContentProps } from '../types';
import { Props } from './types';

export default function View(props: Props) {
    if (isResiInspectionsInfoContentProps(props)) {
        return <InfoContent {...props} />;
    }
    if (isResiInspectionsListContentProps(props)) {
        return <ListContent {...props} />;
    }
}
