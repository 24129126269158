import styled from 'styled-components';
import { spacingHelper, paletteHelper, Button, Inline } from '@rea-group/construct-kit-core';

export const RoundIcon = styled.div`
    display: flex;
    background: ${paletteHelper('backgroundSecondaryLow')};
    padding: ${spacingHelper('small')};
    border-radius: 50%;
`;

export const ListWrapper = styled.div`
    border: 1px solid #e5e3e8;
    border-radius: 1rem;
`;

export const ContentWrapper = styled(Button)`
    @media (hover: hover) {
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
    color: ${paletteHelper('textPrimary')};
    width: 100%;
`;

export const StyledInline = styled(Inline)`
    cursor: pointer;
    width: 100%;
`;
