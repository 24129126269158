// @flow
import { reissueToken as reissueTokenFunc } from '@realestate.com.au/user';

export const withReissueToken =
    <Args, Output>(
        fetchData: (args: Args) => Promise<Output>,
        isTokenExpired: (data: Output) => boolean,
        reissueToken: () => Promise<void> = reissueTokenFunc
    ): ((args: Args) => Promise<Output>) =>
    async (args: Args) => {
        const initialResponse = await fetchData(args);
        if (process.env.WEBPACK_TARGET === 'web' && isTokenExpired(initialResponse)) {
            await reissueToken();
            return fetchData({ ...args, requestPolicy: 'network-only' });
        }
        return initialResponse;
    };
