/* @flow */
import { type OFIViewProps } from '../events-list/types';
import { type SaveToCalendarProps } from '../auction/adaptLexaData';
import { adaptEventData, calendarEventTypeMap } from '../utils';
import type { EventItem, EventTrackers } from './types';
import type { Address, GeneralFeatures, PropertySizes } from '../../../../models/lexa';
import type { Channel } from '../../../../models/Channel';
import type { ListingEventTracker } from '../../../../client/tracking/event';

export type AdaptedData = {
    address: Address,
    events: Array<EventItem>,
    eventTrackers: EventTrackers,
    generalFeatures: ?AdaptedGeneralFeatures,
    propertySize: ?AdaptedPropertySize,
};

export type AdaptedGeneralFeatures = {
    bedrooms: number,
    bathrooms: number,
    studies: number,
    parkingSpaces: number,
};

export type AdaptedPropertySize = {
    type: PropertySizeType,
    value: string,
    unit: string,
};

export type PropertySizeType = 'BUILDING' | 'LAND';

export const adaptData = (
    inspections: OFIViewProps,
    channel: Channel,
    eventTracker: ListingEventTracker,
    generalFeatures: ?GeneralFeatures,
    propertySizes: ?PropertySizes
): AdaptedData => {
    const registrationModalTrackingData = {
        status: channel === 'sold' ? 'sold' : 'active',
        channel: channel,
        inPageSource: 'pdp_inspections_register_cta',
    };

    return {
        generalFeatures: generalFeatures
            ? {
                  bedrooms: generalFeatures.bedrooms.value,
                  bathrooms: generalFeatures.bathrooms.value,
                  studies: generalFeatures.studies.value,
                  parkingSpaces: generalFeatures.parkingSpaces.value,
              }
            : undefined,
        propertySize:
            propertySizes && propertySizes.preferred
                ? {
                      type: propertySizes.preferred.sizeType,
                      value: `${propertySizes.preferred.size.displayValue}`,
                      unit: `${propertySizes.preferred.size.sizeUnit.displayValue}`,
                  }
                : undefined,
        address: inspections[0].address,
        events: inspections.map((inspection: SaveToCalendarProps) => {
            const { display, startTime, endTime } = adaptEventData(inspection.event);
            return {
                event: {
                    eventType: calendarEventTypeMap[inspection.event.plannerEventType],
                    calendarIntegration: inspection.event.calendarIntegration,
                    displayDate: display.longDate,
                    displayTime: display.time,
                },
                input: {
                    listingId: inspection.listingId,
                    eventType: inspection.event.plannerEventType,
                    source: 'REA',
                    startTime,
                    endTime,
                },
            };
        }),
        eventTrackers: {
            addToCalendar: inspections[0].eventTrackers,
            sourcePage: `rea:${channel}:property-details`,
            addToPlan: (startTime: string, endTime: string) =>
                eventTracker.addToPlan({
                    startTime,
                    endTime,
                    status: registrationModalTrackingData.status,
                    channel: registrationModalTrackingData.channel,
                    itemType: 'inspection',
                })()('add'),
            inspectionRegistrationFormOpen: (startTime?: string, endTime?: string) =>
                eventTracker.inspectionRegistrationFormOpen({
                    ...registrationModalTrackingData,
                    startTime,
                    endTime,
                }),
            inspectionRegistrationFormSubmitted: (startTime: string, endTime?: string) =>
                eventTracker.inspectionRegistrationFormSubmitted({
                    ...registrationModalTrackingData,
                    startTime,
                    endTime,
                }),
        },
    };
};
