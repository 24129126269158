/* @flow */

import React from 'react';
import './styles.scss';
import type { PersonalisedInspection } from '../../../../models/lexa';
import { Button } from '@rea-group/construct-kit-core';
import PlannerButton from '../planner-button';
import CalendarButton from '../calendar-button';
import type { ViewProps } from './types';
import { Event } from '@realestate.com.au/planner-add-to-calendar';
import { IRE_OPEN_MODAL_EVENT } from '../create-ire-component';
import type { PlannerEventType } from '../../../../models/lexa';
import { adaptEventData, calendarEventTypeMap } from '../utils';

const CalendarEvent = (props: ViewProps) => {
    const {
        eventTrackers,
        event,
        address,
        listingId,
        templatedUrl,
        channel,
        trackAddToPlan,
        showIreRegisterButton,
        getUser,
        registrationEventTrackers,
        shouldShowRegistrationButton,
    } = props;
    const plannerEventType: PlannerEventType = event.plannerEventType;
    const { display, startTime, endTime } = adaptEventData(event);
    const addToCalendarEvent: Event = {
        eventType: calendarEventTypeMap[plannerEventType],
        displayTime: display.time,
        displayDate: display.longDate,
        calendarIntegration: event.calendarIntegration,
    };
    const eventId = event.personalisationData?.plannerEventId;

    const handleIreRegisterButtonClick = () => {
        if (event.__typename === 'PersonalisedInspection') {
            const selectedOpenStartTime = ((event: any): PersonalisedInspection).startTime;
            document.dispatchEvent(
                new CustomEvent(IRE_OPEN_MODAL_EVENT, {
                    detail: {
                        selectedOpenStartTime,
                    },
                })
            );
        }
    };

    // Map the trackers to triggers
    const plannerButtonCalendarEventTriggers = (inPageSource?: string) => {
        const trackers = eventTrackers(inPageSource);
        return {
            onSaveToCalendar: trackers.trackSaveToCalendar,
            onSaveToCalendarSuccess: trackers.trackSaveToCalendarSuccess,
        };
    };

    return (
        <div className="calendar-event">
            <div className="calendar-event__day-and-time">
                <span className="calendar-event__date">{display.longDate}</span>
                <span className="calendar-event__time">{display.time}</span>
            </div>
            <div className="calendar-event__save">
                {showIreRegisterButton ? (
                    <Button variant="outline" sizeVariant="small" onClick={handleIreRegisterButtonClick}>
                        Register
                    </Button>
                ) : (
                    <PlannerButton
                        getUser={getUser}
                        calendarEventTriggers={plannerButtonCalendarEventTriggers}
                        address={address}
                        listingId={listingId}
                        templatedUrl={templatedUrl}
                        channel={channel}
                        trackAddToPlan={trackAddToPlan}
                        eventId={eventId}
                        addToCalendarEvent={addToCalendarEvent}
                        eventType={plannerEventType}
                        startTime={startTime}
                        endTime={endTime}
                        registrationEventTrackers={registrationEventTrackers}
                        shouldShowRegistrationButton={shouldShowRegistrationButton}
                    />
                )}
                <CalendarButton
                    address={address}
                    eventType={plannerEventType}
                    startTime={startTime}
                    listingId={listingId}
                    templatedUrl={templatedUrl}
                    addToCalendarEvent={addToCalendarEvent}
                    endTime={endTime}
                    eventTrackers={eventTrackers}
                    trackAddToPlan={trackAddToPlan}
                    eventId={eventId}
                    getUser={getUser}
                    shouldShowRegistrationButton={shouldShowRegistrationButton}
                />
            </div>
        </div>
    );
};

export default CalendarEvent;
