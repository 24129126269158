import React from 'react';
import { Text, Stack, Inline, Button, CalendarMd, Card } from '@rea-group/construct-kit-core';
import { RoundIcon } from './styles';
import { SubtitleText } from '../styles';
import { InfoContentProps as Props } from './types';
import ScrollToElement from '../../../../components/details/ScrollToElement';

export const InfoContent = (props: Props) => {
    const { title, subtitle, requestInspectionAction, contactAgentEl } = props;
    const onAfterScroll = () => requestInspectionAction.trackEvent();
    return (
        <Card>
            <Stack inset="large medium" gap="medium">
                <Inline grow={false}>
                    <RoundIcon>
                        <CalendarMd />
                    </RoundIcon>
                </Inline>
                <Stack gap="0">
                    <Text variant="subtitle01">{title}</Text>
                    <SubtitleText variant="body02">{subtitle}</SubtitleText>
                </Stack>
                <Inline grow={false}>
                    <ScrollToElement elementRef={contactAgentEl} afterScroll={onAfterScroll}>
                        {(scrollOnClick) => (
                            <Button variant="secondary" sizeVariant="medium" onClick={scrollOnClick}>
                                {requestInspectionAction.title}
                            </Button>
                        )}
                    </ScrollToElement>
                </Inline>
            </Stack>
        </Card>
    );
};
