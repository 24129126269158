import { GetComponentEventTracker, GetComponentGetters } from '../../../../types';
import compose from '../../../util/functional/typescript/compose';
import { AdaptedProps, InspectionsComponent } from './types';

const dataAdapter = ({
    component,
    getComponentTracker,
}: {
    component: InspectionsComponent | null;
    getComponentTracker: GetComponentEventTracker['getComponentTracker'];
}): AdaptedProps | null => {
    if (!component) return null;

    const { content } = component;

    return {
        header: component.header,
        content: {
            ...content,
            requestInspectionAction: {
                ...content.requestInspectionAction,
                trackEvent: getComponentTracker(content.requestInspectionAction.interactionEventSchemaData),
            },
        },
    };
};

const adaptLexaData = compose(dataAdapter, (data: GetComponentGetters) => {
    return {
        component: data.getComponent('InspectionsComponent'),
        getComponentTracker: data.getComponentTracker,
    };
});

export default adaptLexaData;
