/* @flow */

import { Button } from '@rea-group/construct-kit-core';
import React from 'react';
import { useCheckInspection } from '../../contact-agent/contact-agent-form/ConsumerRequestsProvider';
import ScrollToElement from '../../ScrollToElement';

type ContactAgentLinkProps = {
    onClick: () => void,
    contactAgentEl: () => ?Element,
};

export const ContactAgentLink = ({ onClick, contactAgentEl }: ContactAgentLinkProps) => {
    const checkInspection = useCheckInspection();
    const afterScroll = () => {
        onClick();
        checkInspection();
    };

    return (
        <ScrollToElement elementRef={contactAgentEl} afterScroll={afterScroll}>
            {(scrollOnClick) => (
                <Button variant="link-inline" onClick={scrollOnClick}>
                    Contact the agent
                </Button>
            )}
        </ScrollToElement>
    );
};
