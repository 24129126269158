import React from 'react';
import { Text, Stack, ArrowRightMd } from '@rea-group/construct-kit-core';
import { SubtitleText } from '../styles';
import { ListWrapper, ContentWrapper, StyledInline } from './styles';
import { type ListContentProps } from './types';
import ScrollToElement from '../../../../components/details/ScrollToElement';

export const ListContent = (props: ListContentProps) => {
    const { requestInspectionAction, contactAgentEl } = props;
    const onAfterScroll = () => requestInspectionAction.trackEvent();

    return (
        <ListWrapper>
            <ScrollToElement elementRef={contactAgentEl} afterScroll={onAfterScroll}>
                {(scrollOnClick) => (
                    <ContentWrapper variant="link-primary" onClick={scrollOnClick}>
                        <StyledInline inset="medium" grow={false} justifyContent="space-between">
                            <Stack alignItems="flex-start" gap="none">
                                <Text variant="subtitle01">{requestInspectionAction.title}</Text>
                                <SubtitleText variant="body02">{requestInspectionAction.subtitle}</SubtitleText>
                            </Stack>
                            <ArrowRightMd />
                        </StyledInline>
                    </ContentWrapper>
                )}
            </ScrollToElement>
        </ListWrapper>
    );
};
