import React from 'react';
import { Text, Stack } from '@rea-group/construct-kit-core';
import { SubtitleText } from './styles';
import { HeaderProps } from './types';

export const Header = (props: HeaderProps) => {
    const { seoTitle, subtitle } = props;
    return (
        <Stack gap="0">
            <Text variant="title02">{seoTitle}</Text>
            {subtitle && <SubtitleText variant="body02">{subtitle}</SubtitleText>}
        </Stack>
    );
};
